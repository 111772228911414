// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Autoplay } from "swiper";

// Style
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Container } from "../sc/scLayout";
import {
    SliderWrapper,
    SliderHeading,
    SliderDivider,
    SliderItem,
    SliderItemImg,
    SliderItemTitle,
    SliderButton,
} from "../sc/scSlider";

// Content
import { SERVICE_SHORT_CONTENT, SERVICE_LONG_CONTENT } from "../Content";

SwiperCore.use([Pagination, Autoplay]);

const Slider = ({ allowHeading }) => {
    const renderServices = (content, type = true) => {
        return content.map((service, i) => {
            return (
                <SwiperSlide key={i}>
                    <SliderItem to={`/hizmetler/${service.name}`}>
                        <SliderItemImg
                            src={
                                (type
                                    ? service.img
                                    : service.images && service.images[0]) ||
                                service.imgSlider
                            }
                        />
                        <SliderItemTitle>{service.heading}</SliderItemTitle>
                    </SliderItem>
                </SwiperSlide>
            );
        });
    };

    return (
        <Container>
            <SliderWrapper>
                {allowHeading && (
                    <>
                        <SliderHeading>HİZMETLERİMİZ</SliderHeading>
                        <SliderDivider />
                    </>
                )}
                <Swiper
                    spaceBetween={87.5}
                    slidesPerView={3}
                    autoplay={{ delay: 3000 }}
                    breakpoints={{
                        1600: {
                            slidesPerView: 3,
                            spaceBetween: 105,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 60,
                        },
                        600: {
                            slidesPerView: 2,
                            spaceBetween: 60,
                        },
                        200: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {renderServices(SERVICE_SHORT_CONTENT)}
                    {renderServices(SERVICE_LONG_CONTENT, false)}
                </Swiper>
                <SliderButton to="/katalog">Kataloğu İncele</SliderButton>
            </SliderWrapper>
        </Container>
    );
};

export default Slider;
