import styled from "styled-components";

export const ContactWrapper = styled.div`
    margin: 12.5rem 0;

    @media only screen and (max-width: 600px) {
        margin: 10rem 0;
    }
`;

export const ContactMapWrapper = styled.div`
    width: 55%;

    iframe {
        width: 100%;
        height: 60rem;
    }

    @media only screen and (max-width: 1024px) {
        width: 100%;

        iframe {
            height: 40rem;
        }
    }
`;

export const ContactContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }
`;

export const ContactMain = styled.div`
    width: 40%;

    @media only screen and (max-width: 1024px) {
        width: 100%;
        max-width: 50rem;
        margin-bottom: 10rem;
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 7.5rem;
    }
`;

export const ContactLogo = styled.img`
    width: 7.5rem;
`;

export const ContactHeading = styled.h1`
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    color: #0b0b0f;
    text-transform: uppercase;
    margin: 2rem 0 3.5rem;
    line-height: 1.7;
    font-weight: 500;
`;

export const ContactItem = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`;

export const ContactItemImg = styled.img`
    margin-right: 2rem;
`;

export const ContactItemText = styled.p`
    font-size: 1.4rem;
    color: rgba(11, 11, 15, 0.6);
    line-height: 1.7;
    white-space: pre-line;

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.5s ease;

        &:hover {
            color: rgb(11, 11, 15);
        }
    }
`;

export const ContactButton = styled.a`
    color: white;
    text-decoration: none;
    font-size: 1.4rem;
    text-transform: uppercase;
    background-color: rgba(11, 11, 15);
    transition: all 0.5s ease;
    will-change: transform;
    padding: 2.25rem 4rem;
    margin-top: 2.75rem;
    display: inline-block;

    &:hover {
        color: rgb(11, 11, 15);
        background-color: #e8d186;
        transform: translateY(-5px);
    }
`;
