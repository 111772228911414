import styled from "styled-components";

export const BoxWrapper = styled.div`
    background-color: #f0f0f0;
    padding: 5rem 2.5rem;
    font-size: 2rem;
    text-align: center;
    transition: opacity 0.5s ease;

    &:hover {
        opacity: 0.75;
    }
`;
