import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.div`
    background-color: #262626;
`;

export const FooterTop = styled.div`
    display: flex;
    height: 47.5rem;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        height: auto;
    }
`;

export const FooterBottom = styled.div`
    text-align: center;
    padding: 4rem 0;
    background-color: #1d1d1d;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.4rem;
    line-height: 1.7;

    @media only screen and (max-width: 450px) {
        padding: 4rem 3rem;
        text-align: left;
    }
`;

export const FooterBrandingWrapper = styled(Link)`
    width: 44rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #212121;

    @media only screen and (max-width: 1200px) {
        width: 37.5rem;
    }

    @media only screen and (max-width: 1024px) {
        margin-top: 7.5rem;
        border-radius: 1rem;
        padding: 7.5rem 0;
        width: 100%;
    }
`;

export const FooterLogoImg = styled.img`
    width: 20rem;
    transition: transform 0.5s ease;

    &:hover {
        transform: translateY(-5px);
    }
`;

export const FooterImg = styled.img`
    margin-right: 2rem;
`;

export const FooterRightWrapper = styled.div`
    transform: translateX(5rem);
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 10rem 7.5rem;

    @media only screen and (max-width: 1200px) {
        padding: 10rem 5rem;
    }

    @media only screen and (max-width: 1024px) {
        transform: none;
    }

    @media only screen and (max-width: 700px) {
        padding: 7.5rem 0;
    }

    @media only screen and (max-width: 450px) {
        flex-direction: column;
    }
`;

export const FooterMenu = styled.div`
    @media only screen and (max-width: 450px) {
        margin-bottom: 5rem;
    }
`;

export const FooterLinks = styled.div`
    @media only screen and (max-width: 450px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const FooterHeading = styled.h1`
    color: white;
    font-size: 1.8rem;
    line-height: 1.7;
    font-weight: normal;
`;

export const FooterDivider = styled.hr`
    border: 0.5px solid rgba(255, 255, 255, 0.3);
    width: 5rem;
    margin: 1.75rem 0 2rem;
`;

export const FooterLink = styled(Link)`
    display: block;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    color: ${(props) =>
        props.activeitem === "true" ? "#C6AC57" : "rgba(255, 255, 255, 0.6)"};
    font-size: 1.5rem;
    line-height: 1.7;
    transition: color 0.5s ease;

    &:not(:last-child) {
        margin-bottom: 1.12rem;

        @media only screen and (max-width: 450px) {
            margin-right: 3rem;
        }
    }

    &:hover {
        color: #c6ac57;
    }
`;

export const FooterContact = styled.div`
    max-width: 30rem;
`;

export const FooterContactItem = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`;

export const FooterContactItemText = styled.p`
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.5rem;
    line-height: 1.7;

    @media only screen and (max-width: 450px) {
        width: 100%;
    }

    a {
        color: inherit;
        text-decoration: none;
        transition: color 0.5s ease;

        &:hover {
            color: #c6ac57;
        }
    }
`;

export const FooterContactItemLink = styled.a`
    color: inherit;
    text-decoration: none;
    transition: color 0.5s ease;
`;
