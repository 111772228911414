// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import Documents from "../components/Documents";
import Footer from "../components/Footer";

// Images
import DocumentsBackgroundImg from "../assets/documents-hero.png";
import DocumentsImg from "../assets/documents-img.svg";

const Contact = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={DocumentsBackgroundImg}
                img={DocumentsImg}
                text="DOKÜMANLAR"
            />
            <Documents />
            <Footer />
        </>
    );
};

export default Contact;
