// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import ServicesRenderer from "../components/Services";
import Footer from "../components/Footer";

// Images
import ServiceBackgroundImg from "../assets/services-hero.png";
import ServiceImg from "../assets/services-img.svg";

const Services = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={ServiceBackgroundImg}
                img={ServiceImg}
                text="HİZMETLERİMİZ"
            />
            <ServicesRenderer />
            <Footer />
        </>
    );
};

export default Services;
