// Styles
import { Container } from "../sc/scLayout";
import {
    HeroWrapper,
    HeroBackground,
    HeroContainer,
    HeroImg,
    HeroText,
} from "../sc/scHero";

const Hero = ({ backgroundImg, img, text }) => {
    return (
        <HeroWrapper>
            <HeroBackground src={backgroundImg} />
            <Container>
                <HeroContainer>
                    <HeroImg src={img} />
                    <HeroText>{text}</HeroText>
                </HeroContainer>
            </Container>
        </HeroWrapper>
    );
};

export default Hero;
