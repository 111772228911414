import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const slideDown = keyframes`
    0% {
        transform: scaleX(100%);
    }
    100% {
        transform: scaleX(0);
    }
`;

export const HeroAnimated = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #292929;
    z-index: 10;
    animation: ${slideDown} 1.5s cubic-bezier(0.76, 0.05, 0.44, 1) forwards;
    transform-origin: left;
`;

export const HeroWrapper = styled.div`
    width: 100%;
    height: 90rem;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1600px) {
        height: 75rem;
    }

    @media only screen and (max-width: 600px) {
        height: 60rem;
    }
`;

export const HeroImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: left center;
`;

export const HeroHeading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -35%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    display: none;
    overflow: hidden;

    h1 {
        opacity: 0;
        color: white;
        font-size: 4rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.7;
        letter-spacing: 1px;
        transform: translateY(200%);

        @media only screen and (max-width: 600px) {
            font-size: 2.7rem;
        }

        @media only screen and (max-width: 330px) {
            font-size: 2.6rem;
        }
    }
`;

export const HeroButton = styled(Link)`
    opacity: 0;
    margin-top: 3rem;
    color: rgb(11, 11, 15);
    display: inline-block;
    padding: 2.25rem 3.5rem;
    font-size: 1.3rem;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.5px;
    background-color: #f5d776;
    transform: translateY(200%);
    transition: background-color 0.5s ease;

    &:hover {
        background-color: white;
    }
`;
