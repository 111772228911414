// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import Slider from "../components/Slider";
import Footer from "../components/Footer";

// Images
import AboutBackgroundImg from "../assets/about-hero.jpg";
import AboutImg from "../assets/about-img.svg";

const About = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={AboutBackgroundImg}
                img={AboutImg}
                text="HAKKIMIZDA"
            />
            <AboutUs />
            <Slider />
            <Footer />
        </>
    );
};

export default About;
