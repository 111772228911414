import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
    0% {
        background-color: white;
    }
    100% {
        background-color: rgb(11, 11, 15);
    }
`;

const slideLeft = keyframes`
    0% {
        transform: translateX(-100%); 
    }
    100% {
        transform: none;
    }
`;

const slideUp = keyframes`
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: none;
    }
`;

export const CatalogWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    animation: ${fadeIn} 1s ease forwards;

    & > div:not(:first-child) {
        transform: translateY(100vh);
        animation: ${slideUp} 1s ease forwards 1s;
    }

    & > div:last-child {
        display: none;
    }

    @media only screen and (max-width: 1024px) {
        .animated {
            display: none !important;
        }

        & > div:last-child {
            display: block;
            margin: 0 !important;
        }

        img {
            width: 90%;
        }

        .swiper-container {
            max-width: 60rem;
        }

        .swiper-slide {
            width: 100% !important;
        }
    }
`;

export const DecorationLeft = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 50vh;
    z-index: 0;
    background-color: #f5d776;
    transform: translateX(-100%);
    animation: ${slideLeft} 1s ease forwards 0.5s;

    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

export const Page = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: rgb(11, 11, 15);

    &:nth-child(2n) {
        background-color: lightcoral;
    }
`;
