import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

// Styles
import { Container } from "../sc/scLayout";
import {
    HeaderWrapper,
    HeaderContainer,
    Logo,
    Menu,
    MenuItem,
    MenuDropdownWrapper,
    MenuDropdown,
    MenuDropdownItem,
    MenuLink,
    MenuLinkExternal,
    MenuButton,
    MenuMobileContainer,
    MenuMobile,
    MenuSocials,
    MenuSocial,
} from "../sc/scHeader";

// Images
import LogoImg from "../assets/logo.png";
import LogoDarkImg from "../assets/logo-dark.png";
import YoutubeIcon from "../assets/youtube.svg";
import LinkedinIcon from "../assets/linkedin.svg";
import InstagramIcon from "../assets/instagram.svg";

// Content
import {
    ABOUT_CONTENT,
    SERVICE_SHORT_CONTENT,
    SERVICE_LONG_CONTENT,
} from "../Content";

// Constants
const MENU = [
    {
        link: "/",
        text: "Anasayfa",
    },
    {
        link: "/hakkimizda",
        text: "Hakkımızda",
        dropdown: [...ABOUT_CONTENT],
    },
    {
        link: "/hizmetler",
        text: "HİZMETLERİMİZ",
        dropdown: [...SERVICE_SHORT_CONTENT, ...SERVICE_LONG_CONTENT],
    },
    {
        link: "/dokumanlar",
        text: "Dokümanlar",
    },
    {
        external: true,
        link: "https://medium.com/@ayardenetim",
        text: "Blog",
    },
    {
        link: "/iletisim",
        text: "İLETİŞİM",
    },
];

const Header = ({ transparent, animatedHeader }) => {
    const location = useLocation();
    const [isTransparent, setIsTransparent] = useState(transparent);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuEvent = () => {
        const el = document.querySelector(".header");
        let top = window.pageYOffset || document.documentElement.scrollTop;

        if (el) {
            if (top > 100 && el?.style?.padding === "3rem") {
                if (isTransparent) setIsTransparent(false);
                el.style.padding = "1rem 3rem";
            }

            if (top <= 100 && el?.style?.padding !== "3rem") {
                if (isTransparent) setIsTransparent(true);
                el.style.padding = "3rem";
            }
        }
    };

    useEffect(() => {
        menuEvent();
        document.addEventListener("scroll", menuEvent);

        return () => {
            document.removeEventListener("scroll", menuEvent);
        };
    }, []);

    const renderDropdown = (menuItem) => {
        return (
            <MenuDropdownWrapper>
                <MenuDropdown>
                    {menuItem.dropdown.map((dropdownItem, i) => (
                        <MenuDropdownItem
                            key={i}
                            to={`${menuItem.link}/${dropdownItem.name}`}
                        >
                            {dropdownItem.heading}
                        </MenuDropdownItem>
                    ))}
                </MenuDropdown>
            </MenuDropdownWrapper>
        );
    };

    return (
        <HeaderWrapper
            transparent={transparent && isTransparent ? "true" : "false"}
            animatedHeader={animatedHeader ? "true" : "false"}
        >
            <Container>
                <HeaderContainer className="header">
                    <Link to="/">
                        <Logo
                            className="logo"
                            src={isTransparent ? LogoDarkImg : LogoImg}
                        />
                    </Link>
                    <MenuMobileContainer
                        onClick={() => setIsMenuOpen((menuOpen) => !menuOpen)}
                    >
                        <MenuMobile
                            menuOpen={isMenuOpen ? "true" : "false"}
                            transparent={
                                transparent && isTransparent ? "true" : "false"
                            }
                        ></MenuMobile>
                    </MenuMobileContainer>
                    <Menu
                        menuOpen={isMenuOpen ? "true" : "false"}
                        transparent={
                            transparent && isTransparent ? "true" : "false"
                        }
                    >
                        {MENU.map((menuItem, i) => (
                            <MenuItem key={i}>
                                {menuItem?.external ? (
                                    <MenuLinkExternal href={menuItem.link}>
                                        {menuItem.text}
                                    </MenuLinkExternal>
                                ) : (
                                    <MenuLink
                                        to={menuItem.link}
                                        onClick={() =>
                                            location.pathname ===
                                                menuItem.link &&
                                            setIsMenuOpen(false)
                                        }
                                        activeitem={
                                            location.pathname === menuItem.link
                                                ? "true"
                                                : "false"
                                        }
                                    >
                                        {menuItem.text}
                                    </MenuLink>
                                )}
                                {menuItem.dropdown && renderDropdown(menuItem)}
                            </MenuItem>
                        ))}
                        <MenuSocials>
                            <MenuSocial href="https://www.youtube.com/channel/UCMXCYmRiJOKZQEsHPsXxKqw">
                                <img
                                    src={YoutubeIcon}
                                    alt="Ayar Denetim Youtube"
                                />
                            </MenuSocial>
                            <MenuSocial href=" https://www.linkedin.com/company/ayar-deneti%CC%87m/">
                                <img
                                    src={LinkedinIcon}
                                    alt="Ayar Denetim LinkedIn"
                                />
                            </MenuSocial>
                            <MenuSocial href="https://www.instagram.com/ayardenetim/">
                                <img
                                    src={InstagramIcon}
                                    alt="Ayar Denetim Instagram"
                                />
                            </MenuSocial>
                        </MenuSocials>
                        <MenuButton
                            onClick={() => setIsMenuOpen(false)}
                            href="mailto:info@ayardenetim.com"
                            transparent={
                                transparent && isTransparent ? "true" : "false"
                            }
                        >
                            TEKLİF AL
                        </MenuButton>
                    </Menu>
                </HeaderContainer>
            </Container>
        </HeaderWrapper>
    );
};

export default Header;
