// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import ContactDetails from "../components/ContactDetails";
import Footer from "../components/Footer";

// Images
import ContactBackgroundImg from "../assets/contact-hero.png";
import ContactImg from "../assets/contact-img.svg";

const Contact = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={ContactBackgroundImg}
                img={ContactImg}
                text="İLETİŞİM"
            />
            <ContactDetails />
            <Footer />
        </>
    );
};

export default Contact;
