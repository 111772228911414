import { Link } from "react-router-dom";
import styled from "styled-components";

export const AboutContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15rem 0 11rem;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }

    .about-main {
        width: 100%;
    }
`;

export const AboutMain = styled.div`
    width: 45%;

    @media only screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 7.5rem;
    }
`;

export const AboutHeading = styled.h1`
    font-size: 2rem;
    font-weight: 500;
    color: rgb(11, 11, 15);
    text-transform: uppercase;
`;

export const AboutDivider = styled.hr`
    margin: 1.75rem 0 3rem;
    width: 5rem;
    border: 0.5px solid rgb(11, 11, 15);
`;

export const AboutText = styled.p`
    font-size: 1.4rem;
    color: rgba(11, 11, 15, 0.6);
    line-height: 1.7;
`;

export const AboutButton = styled(Link)`
    margin-top: 4rem;
    display: inline-block;
    padding: 2rem 4rem;
    text-decoration: none;
    border: 1px solid rgb(11, 11, 15);
    color: rgb(11, 11, 15);
    font-size: 1.3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.5s ease, background-color 0.5s ease;

    &:hover {
        color: white;
        background-color: rgb(11, 11, 15);
    }
`;

export const AboutImgContainer = styled.div`
    width: 45%;
    height: 51.5rem;
    background-color: #1a1a22;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;

export const AboutImg = styled.img`
    width: 33rem;
`;

// Reverse
export const AboutReverseWrapper = styled.div`
    position: relative;
    height: 75rem;
    margin: 20rem 0;

    @media only screen and (max-width: 1600px) {
        height: 60rem;
        margin: 15rem 0;
    }

    @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        margin: 10rem 0;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }
`;

export const AboutReverseImgContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 45%;
    height: 100%;
    background-color: #1a1a22;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        width: 85%;
        position: static;
        margin: 0 auto;
        height: 40rem;
        margin-top: 7.5rem;
    }
`;

export const AboutReverseImg = styled.img`
    width: 80%;
    max-width: 40rem;
`;

export const AboutReverseContentWrapper = styled.div`
    height: 100%;

    & > div {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

export const AboutReverseContent = styled.div`
    width: 47.5rem;

    @media only screen and (max-width: 1200px) {
        width: 42.5rem;
    }

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }
`;
