import styled from "styled-components";

export const ServiceWrapper = styled.div`
    margin: 15rem 0;

    @media only screen and (max-width: 1024px) {
        margin: 10rem 0;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }
`;

export const ServiceContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
        width: 27%;
        margin: 5rem 0;
        text-align: center;

        @media only screen and (max-width: 1300px) {
            width: 30%;
        }

        @media only screen and (max-width: 1024px) {
            width: 45%;
        }

        @media only screen and (max-width: 700px) {
            width: 100%;
        }
    }
`;

export const ServiceShortWrapper = styled.div`
    margin: 15rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1024px) {
        margin: 10rem 0;
        flex-direction: column;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }

    ${(props) =>
        props.reverse === "true" &&
        `
        flex-direction: row-reverse;
    `};

    ul {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    li {
        line-height: 2;
        padding-left: 3rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Group_4570' data-name='Group 4570' transform='translate(-126 -4348)'%3E%3Cg id='Rectangle_1823' data-name='Rectangle 1823' transform='translate(126 4348)' fill='%23fff' stroke='%23ff9b4d' stroke-width='1'%3E%3Crect width='16' height='16' rx='4' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='3.5' fill='none'/%3E%3C/g%3E%3Cpath id='Path_27660' data-name='Path 27660' d='M13704.042-4508.729l1.871 1.871 4.6-4.6' transform='translate(-13573.279 8865.159)' fill='none' stroke='%23ff9b4d' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        list-style: none;

        &:not(:last-child) {
            margin-bottom: 7.5px;
        }
    }
`;

export const ServiceShortContainer = styled.div`
    width: 45%;

    @media only screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 7.5rem;
    }
`;

export const ServiceHeading = styled.h1`
    font-size: 1.8rem;
    font-weight: 500;
    color: rgb(11, 11, 15);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: pre-line;
    line-height: 1.7;
`;

export const ServiceDivider = styled.hr`
    width: 5rem;
    margin: 2rem 0 3rem;
    border: 0.5px solid rgb(11, 11, 15);
`;

export const ServiceText = styled.div`
    font-size: 1.5rem;
    color: rgba(11, 11, 15, 0.6);
    white-space: pre-line;
    line-height: 1.7;
`;

export const ServiceShortPhoto = styled.img`
    width: 45%;
    height: 60rem;
    object-fit: cover;

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    @media only screen and (max-width: 600px) {
        height: 50rem;
    }
`;

export const ServiceLongWrapper = styled.div`
    margin: 12.5rem 0;

    @media only screen and (max-width: 1024px) {
        margin: 10rem 0;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }

    .divider {
        margin: 5rem 0;
        border: 0.5px solid rgb(11, 11, 15, 0.1);
    }

    .methods {
        margin: 5rem 0 7.5rem;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 1024px) {
            flex-direction: column;
        }

        .method {
            width: 45%;

            @media only screen and (max-width: 1024px) {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 5rem;
                }
            }

            .method-header {
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 2rem;

                .method-icon {
                    width: 7.5rem;
                    height: 7.5rem;
                    font-size: 2rem;
                    border: 0.5px solid #c5ac57;
                    color: #c5ac57;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                h1 {
                    font-size: 2rem;
                    font-weight: normal;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    color: rgb(11, 11, 15);
                    margin-left: 3rem;

                    @media only screen and (max-width: 600px) {
                        width: 65%;
                    }
                }
            }
        }
    }

    div {
        @media only screen and (max-width: 1024px) {
            max-width: 100%;
        }
    }

    ul {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    li {
        line-height: 2;
        padding-left: 3rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Group_4570' data-name='Group 4570' transform='translate(-126 -4348)'%3E%3Cg id='Rectangle_1823' data-name='Rectangle 1823' transform='translate(126 4348)' fill='%23fff' stroke='%23ff9b4d' stroke-width='1'%3E%3Crect width='16' height='16' rx='4' stroke='none'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='3.5' fill='none'/%3E%3C/g%3E%3Cpath id='Path_27660' data-name='Path 27660' d='M13704.042-4508.729l1.871 1.871 4.6-4.6' transform='translate(-13573.279 8865.159)' fill='none' stroke='%23ff9b4d' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        list-style: none;

        &:not(:last-child) {
            margin-bottom: 7.5px;
        }
    }
`;

export const ServiceLongImages = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 7.5rem;

    @media only screen and (max-width: 1024px) {
        flex-direction: column;
    }

    ${(props) =>
        props.singleImage === "true" &&
        `
        height: 50rem;
    `};
`;

export const ServiceLongImage = styled.img`
    width: 45%;
    height: 100%;
    object-fit: cover;

    ${(props) =>
        props.singleImage === "true" &&
        `
        width: 100%;
    `};

    ${(props) =>
        props.singleImage !== "true" &&
        `
        height: 40rem;

        &:not(:last-child) {
            margin-right: 7.5rem;

            @media only screen and (max-width: 1024px) {
                margin-right: 0;
                margin-bottom: 7.5rem;
            }
        }

        @media only screen and (max-width: 1024px) {
            height: 100%;
            width: 100%;
        }
    `};
`;
