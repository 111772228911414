// Styles
import { Container } from "../sc/scLayout";
import {
    ServiceWrapper,
    ServiceContainer,
    ServiceHeading,
    ServiceDivider,
} from "../sc/scService";
import { SliderItem, SliderItemImg, SliderItemTitle } from "../sc/scSlider";

// Content
import { SERVICE_SHORT_CONTENT, SERVICE_LONG_CONTENT } from "../Content";

const Services = () => {
    const renderServices = (content, type = true) => {
        return content.map((service, i) => (
            <SliderItem key={i} to={service.name}>
                <SliderItemImg
                    src={
                        (type
                            ? service.img
                            : service.images && service.images[0]) ||
                        service.imgSlider
                    }
                />
                <SliderItemTitle>{service.heading}</SliderItemTitle>
            </SliderItem>
        ));
    };

    return (
        <Container>
            <ServiceWrapper>
                <ServiceHeading>Tüm Hizmetler</ServiceHeading>
                <ServiceDivider />
                <ServiceContainer>
                    {renderServices(SERVICE_SHORT_CONTENT)}
                    {renderServices(SERVICE_LONG_CONTENT, false)}
                </ServiceContainer>
            </ServiceWrapper>
        </Container>
    );
};

export default Services;
