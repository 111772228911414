// Styles
import { Container } from "../sc/scLayout";
import {
    AboutContainer,
    AboutMain,
    AboutHeading,
    AboutDivider,
    AboutText,
} from "../sc/scAbout";

const Single = ({ CONTENT }) => {
    return (
        <Container>
            <AboutContainer>
                <AboutMain className="about-main">
                    <AboutHeading>{CONTENT.heading}</AboutHeading>
                    <AboutDivider />
                    <AboutText>{CONTENT.text}</AboutText>
                </AboutMain>
            </AboutContainer>
        </Container>
    );
};

export default Single;
