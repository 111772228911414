import styled from "styled-components";
import { Link } from "react-router-dom";

export const SliderWrapper = styled.div`
    margin: 8rem 0 12.5rem;
    text-align: center;

    .swiper-container {
        cursor: grab;
        padding: 5rem 0 3rem;

        @media only screen and (max-width: 600px) {
            padding: 2.5rem 0 2.5rem;
        }
    }

    .swiper-pagination-bullet-active {
        background-color: red;
    }

    @media only screen and (max-width: 1024px) {
        margin: 10rem 0;
    }

    @media only screen and (max-width: 600px) {
        margin: 7.5rem 0;
    }
`;

export const SliderHeading = styled.h1`
    font-size: 1.8rem;
    font-weight: 500;
    color: rgb(11, 11, 15);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: pre-line;
    line-height: 1.7;
    text-align: center;
    margin-top: 10rem;
`;

export const SliderDivider = styled.hr`
    width: 5rem;
    margin: 2rem auto 3rem;
    border: 0.5px solid rgb(11, 11, 15);
`;

export const SliderItem = styled(Link)`
    width: 100%;
    height: 50rem;
    display: inline-block;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1600px) {
        height: 45rem;
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
`;

export const SliderItemImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
`;

export const SliderItemTitle = styled.h1`
    margin-top: 2rem;
    color: white;
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: 500;
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 0 2rem;
    height: 10rem;
    transform: translateY(1rem);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(11, 11, 15, 0.65);
`;

export const SliderButton = styled(Link)`
    color: rgb(11, 11, 15);
    text-decoration: none;
    font-size: 1.4rem;
    text-transform: uppercase;
    background-color: #f5d776;
    transition: all 0.5s ease;
    will-change: transform;
    padding: 2.25rem 4rem;
    margin-top: 5rem;
    display: inline-block;

    @media only screen and (max-width: 600px) {
        margin-top: 3.5rem;
    }

    &:hover {
        opacity: 0.75;
        transform: translateY(-5px);
    }
`;
