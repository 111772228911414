import React from "react";

function Blog() {
    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "2.6rem",
                color: "rgb(11, 11, 15)",
                textTransform: "uppercase",
                letterSpacing: "2px",
                fontWeight: "normal",
            }}
        >
            Yakında
        </div>
    );
}

export default Blog;
