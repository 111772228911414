import { useState } from "react";

// Images
import DocImg from "../assets/document.svg";

// Styles
import { Container } from "../sc/scLayout";
import {
    DocumentsWrapper,
    DocumentsHeader,
    DocumentLink,
    DocumentsMain,
    DocumentContainer,
    DocumentMain,
    DocumentTag,
    DocumentText,
    DocumentImg,
} from "../sc/scDocuments.js";

// Constants
const DocumentItems = [
    {
        type: "first",
        link: "ayar-denetim-akreditasyon",
        tag: "PDF",
        text: "AKREDİTASYON SERTİFİKASI",
    },
    {
        type: "first",
        link: "sikayet-itiraz-ve-oneri-formu",
        tag: "PDF",
        text: "ŞİKAYET, İTİRAZ VE\nÖNERİ FORMU",
    },
    {
        type: "first",
        link: "muayene-oncesi-on-hazirlik-formu",
        tag: "PDF",
        text: "Muayene Öncesi Ön Hazirlik Formu",
    },
    {
        type: "first",
        link: "sikayet-ve-itiraz-proseduru",
        tag: "PDF",
        text: "ŞİKAYET VE İTİRAZ\nPROSEDÜRÜ",
    },
    {
        type: "second",
        link: "4857-sayılı-is-kanunu",
        tag: "PDF",
        text: "4857 Sayılı\nİş Kanunu",
    },
    {
        type: "second",
        link: "6331-sayili-is-sagligi-ve-guvenligi-kanunu",
        tag: "PDF",
        text: "6331 Sayılı İş Sağlığı\nve Güvenliği Kanunu",
    },
    {
        type: "second",
        link: "is-ekipmanlarinin-kullaniminda-saglik-ve-guvenlik-sartlari-yonetmeligi",
        tag: "PDF",
        text: "İş Ekipmanlarının Kullanımında\nSağlık ve Güvenlik Şartları\nYönetmeliği",
    },
    {
        type: "second",
        link: "elektrik-tesislerinde-topraklamalar-yonetmeligi",
        tag: "PDF",
        text: "Elektrik Tesislerinde\nTopraklamalar Yönetmeliği",
    },
    {
        type: "second",
        link: "elektrik-ic-tesisleri-yonetmeligi",
        tag: "PDF",
        text: "Elektrik İç Tesisleri\nYönetmeliği",
    },
    {
        type: "second",
        link: "kuvvetli-akim-tesisleri-yonetmeligi",
        tag: "PDF",
        text: "Elektrik Kuvvetli Akım\nTesisleri Yönetmeliği",
    },
    {
        type: "second",
        link: "is-ekipmanlari-yonetmeliginde-degisiklik-yapilmasina-dair-yonetmelik",
        tag: "PDF",
        text: "İş Ekipmanları Yönetmeliğinde\nDeğişiklik Yapılmasına Dair Yönetmelik",
    },
];

const Document = ({ visible, link, tag, text }) => {
    return (
        <DocumentContainer style={{ display: visible ? "flex" : "none" }}>
            <DocumentImg src={DocImg} />
            <DocumentMain
                target="_blank"
                href={
                    process.env.PUBLIC_URL +
                    "/pdf/" +
                    link +
                    (tag === "PDF" ? ".pdf" : ".docx")
                }
            >
                <DocumentTag>{tag}</DocumentTag>
                <DocumentText>{text}</DocumentText>
            </DocumentMain>
        </DocumentContainer>
    );
};

const Documents = () => {
    const [type, setType] = useState("all");

    return (
        <Container>
            <DocumentsWrapper>
                <DocumentsHeader>
                    <DocumentLink
                        activeitem={type === "all" ? "true" : "false"}
                        onClick={() => setType("all")}
                    >
                        Hepsi
                    </DocumentLink>
                    <DocumentLink
                        activeitem={type === "first" ? "true" : "false"}
                        onClick={() => setType("first")}
                    >
                        Genel Dokümanlar
                    </DocumentLink>
                    <DocumentLink
                        activeitem={type === "second" ? "true" : "false"}
                        onClick={() => setType("second")}
                    >
                        Kanun ve Yönetmelikler
                    </DocumentLink>
                </DocumentsHeader>
                <DocumentsMain>
                    {DocumentItems.map((documentItem, i) => (
                        <Document
                            key={i}
                            visible={
                                type === documentItem.type || type === "all"
                            }
                            link={documentItem.link}
                            tag={documentItem.tag}
                            text={documentItem.text}
                        />
                    ))}
                </DocumentsMain>
            </DocumentsWrapper>
        </Container>
    );
};

export default Documents;
