// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import Single from "../components/Single";
import Footer from "../components/Footer";

// Images
import AboutBackgroundImg from "../assets/about-hero.jpg";
import AboutImg from "../assets/about-img.svg";

const CONTENT = {
    heading:
        "AYAR DENETİM TEST ÖLÇÜM VE PERİYODİK KONTROL HİZMETLERİ LTD. ŞTİ.;",
    text: (
        <>
            Yetkilisi bulunduğum TS EN ISO/IEC 17020 Standartı’na göre A Tipi
            Muayene Kuruluşu olan Ayar Denetim Test Ölçüm ve Periyodik Kontrol
            Hizmetleri Ltd. Şti. tarafından gerçekleştirilecek muayene
            hizmetlerin tarafsızlığından ve bağımsızlığından sorumlu olacağımı,
            <br />
            <br />
            Tarafsızlığı tehlikeye atacak ticari, mali veya diğer baskılara izin
            vermeyeceğimi,
            <br />
            <br />
            Tarafsızlığa yönelik oluşabilecek riskleri devamlı surette analiz
            edeceğimi/ettireceğimi ve düzenli periyotlarla risk analizi
            gerçekleştirilerek bu analizin tüm personel tarafından anlaşılmasını
            sağlayacağımı,
            <br />
            <br />
            Tarafsızlığa yönelik bir risk belirlendiğinde, böyle bir riskin
            nasıl ortadan kaldırılacağını ya da en aza indirileceğini
            belirleyeceğimi,
            <br />
            <br />
            Muayene faaliyetleri esnasında kararların ve doğruluklarının
            tarafsız ve bağımsızlığı ile çelişecek, çıkar çatışması yaratacak
            hiçbir faaliyette bulunmayacağımı,
            <br />
            <br />
            Muayene faaliyetlerinde yer alan öğelerin üreticisi, tedarikçisi,
            montajcısı, satın alıcısı, sahibi, kullanıcısı, bakımcısı veya
            bunların yetkili temsilcisi durumunda olmayacağımı; bu faaliyetleri
            gerçekleştiren bir tüzel kişiliğin parçası olmayacağımı ve böyle bir
            tüzel kişilikle muayene sonuçlarını etkileyemeyecek durumlar hariç
            sahiplik, sahipliği olanların temsilciliği, aynı üst yönetim
            kademesine bağlılık gibi bağlantılarımın olmayacağımı; böyle bir
            tüzel kişilikle muayene sonuçların etkileyebilecek sözleşmeye
            bağlanmış taahhütler yapmayacağımı,
            <br />
            <br />
            Yasal geçerliliği olan taahhütler aracılığı ile muayene
            faaliyetlerinin gerçekleştirilmesi boyunca elde edilen veya
            oluşturulan tüm bilgilerin yönetiminden sorumlu olacağımı,
            <br />
            <br />
            Kamuya açık ortamlarda yayınlamayı öngördüğü bilgilerle ilgili
            olarak müşteriyi önceden bilgilendireceğimi,
            <br />
            <br />
            Müşterinin kamuya açık hale getirdiği veya Ayar Denetim ile
            müşterimizin aralarında anlaşmaya vardığı bilgiler hariç diğer tüm
            bilgilerin sahibine özel bilgiler olarak dikkate alacağımı ve gizli
            kabul edeceğimi,
            <br />
            <br />
            Yasal zorunluluktan dolayı veya sözleşmeye bağlanmış taahhütlerden
            alınan yetkiye istinaden gizli bilgileri açıklama durumunda, yasal
            bir engel yoksa verilen bilgileri müşteriye veya ilgili kişilere
            bildireceğimi,
            <br />
            <br />
            Müşterilerimiz ile ilgili olarak müşterilerimiz dışındaki
            kaynaklardan elde edilen bilgileri gizli olarak değerlendireceğimi,
            <br />
            <br />
            Bütün taraflardan gelebilecek iş ve hizmet taleplerine eşit şekilde
            karşılık vereceğimi, herhangi bir ayrımcılık yapmayacağımı, gereksiz
            ek bir külfet (maddi vb.) talebinde bulunmayacağımı ve bütün kuruluş
            içi prosedürleri müşterilerimiz arasında ayrım yapılmadan
            uygulayacağımı,
            <br />
            <br />
            Ayar Denetim bünyesinde yapılan işleri herhangi bir şekilde kendi
            menfaatime veya başka kurumlar menfaatine kullanmayacağımı,
            <br />
            <br />
            Ayar Denetim bünyesinde çalışan personelin hiçbir kuruluş ile çıkar
            ilişkisi içerisine girmesini talep etmeyeceğimi, çıkar sağlayıcı
            herhangi bir talep gelmesi durumunda bu talebi kesinlikle
            reddedeceğimi,
            <br />
            <br />
            Ayar Denetim muayene personelinin alacağı ücretin muayene sayısı ve
            sonuçlarıyla ilgili olmayacağını,
            <br />
            <br />
            Ayar Denetim kalite yönetim sistemi kapsamında uygulanmakta olan tüm
            prosedürlere, kanun, yönetmelik vb. mevzuata, ulusal ve uluslararası
            standartlara ayırım yapmaksızın uyulmasını sağlayacağımı,
            <br />
            <br />
            Şikayet ve itirazların prosedürlerimiz kapsamında ve doğruluk,
            tarafsızlık, bağımsızlık, gizlilik, şeffaflık, sorumluluk ilkeleri
            çerçevesinde en hızlı şekilde çözümlenmesini sağlayacağımı ve
            <br />
            <br />
            Yukarıdaki tüm maddelerde TS EN ISO/IEC 17020 Standardı şartlarına
            bağlı kalacağımı kabul, beyan ve taahhüt ederim.
            <br />
            <br />
            <b>Genel Müdür</b>
            <br />
            <b>08.06.2022</b>
        </>
    ),
};

const Text = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={AboutBackgroundImg}
                img={AboutImg}
                text="TARAFSIZLIK, BAĞIMSIZLIK VE GİZLİLİK TAAHHÜTNAMESİ"
            />
            <Single CONTENT={CONTENT} />
            <Footer />
        </>
    );
};

export default Text;
