import { useState } from "react";

// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import { ServiceShort, ServiceLong } from "../components/Service";

// Images
import ServiceBackgroundImg from "../assets/services-hero.png";
import ServiceImg from "../assets/services-img.svg";

const Service = ({ content, type }) => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={ServiceBackgroundImg}
                img={ServiceImg}
                text={`${content?.heading}`}
            />
            {type === "short" ? (
                <ServiceShort content={content} />
            ) : (
                <ServiceLong content={content} />
            )}
            <Footer />
        </>
    );
};

export default Service;
