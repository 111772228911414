// Components
import Header from "../components/Header";
import HeroSlider from "../components/HeroSlider";
import Slider from "../components/Slider";
import AboutUs from "../components/AboutUs";
import ContactDetails from "../components/ContactDetails";
import Footer from "../components/Footer";
import { BoxWrapper } from "../sc/scBox";

const Home = () => {
    return (
        <>
            <Header transparent animatedHeader />
            <HeroSlider />
            <BoxWrapper>
                AYAR DENETİM TEST ÖLÇÜM VE PERİYODİK KONTROL HİZMETLERİ A TİPİ
                AKREDİTE MUAYENE KURULUŞU AB-0722-M
            </BoxWrapper>
            <Slider allowHeading />
            <AboutUs reverse />
            <ContactDetails />
            <Footer />
        </>
    );
};

export default Home;
