// Styles
import { Container } from "../sc/scLayout";
import {
    ServiceHeading,
    ServiceDivider,
    ServiceText,
    ServiceShortWrapper,
    ServiceShortContainer,
    ServiceShortPhoto,
    ServiceLongWrapper,
    ServiceLongImages,
    ServiceLongImage,
} from "../sc/scService";

export const ServiceShortRenderer = ({ heading, text, img, reverse }) => {
    return (
        <ServiceShortWrapper reverse={reverse ? "true" : "false"}>
            <ServiceShortContainer>
                {heading && (
                    <>
                        <ServiceHeading>{heading}</ServiceHeading>
                        <ServiceDivider />
                    </>
                )}
                <ServiceText>{text}</ServiceText>
            </ServiceShortContainer>
            <ServiceShortPhoto src={img} />
        </ServiceShortWrapper>
    );
};

const ServiceLongRenderer = ({ heading, text, images, type }) => {
    return (
        <ServiceLongWrapper>
            <ServiceHeading>{heading}</ServiceHeading>
            <ServiceDivider />
            <ServiceText>{text}</ServiceText>
            {images && (
                <ServiceLongImages singleImage={type ? "true" : "false"}>
                    {images.map((image, i) => (
                        <ServiceLongImage
                            key={i}
                            src={image}
                            singleImage={type ? "true" : "false"}
                        />
                    ))}
                </ServiceLongImages>
            )}
        </ServiceLongWrapper>
    );
};

export const ServiceShort = ({ content }) => {
    return (
        <Container>
            <ServiceShortRenderer
                heading={content.heading}
                text={content.text}
                img={content.img}
            />
            {content.more && (
                <ServiceShortRenderer
                    heading={content.more.heading}
                    text={content.more.text}
                    img={content.more.img}
                    reverse
                />
            )}
        </Container>
    );
};

export const ServiceLong = ({ content }) => {
    return (
        <Container>
            <ServiceLongRenderer
                heading={content.heading}
                text={content.text}
                images={content.images}
                type={content.type}
            />
        </Container>
    );
};
