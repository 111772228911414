import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    *,
    *:before,
    *:after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        // Anti aliasing
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        font-size: 62.5%;
        font-family: 'Montserrat', sans-serif;

        @media only screen and (max-width: 1024px) {
            font-size: 60%;
        }

        @media only screen and (max-width: 600px) {
            font-size: 57.5%;
        }
    }

    body {
        width: 100%;
        min-height: 100vh;
    }
`;
