import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";

const slideDown = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-150%);
    }
    100% {
        opacity: 1;
        transform: none;
    }
`;

export const HeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: white;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.6px);
    -webkit-backdrop-filter: blur(6.6px);
    transition: background-color 0.5s ease;

    ${(props) =>
        props.animatedHeader === "true" &&
        css`
            opacity: 0;
            transform: translateY(-150%);
            animation: ${slideDown} 1.5s cubic-bezier(0.76, 0.05, 0.44, 1)
                forwards 0.5s;
        `};

    ${(props) =>
        props.transparent === "true" &&
        `
        background-color: transparent;
        border-bottom: .5x solid rgba(255, 255, 255, .1);
        box-shadow: none;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    `};
`;

export const HeaderContainer = styled.div`
    width: 100%;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.5s ease;
`;

export const Logo = styled.img`
    width: 17.8rem;
    transform: translateX(-4rem);

    @media only screen and (max-width: 600px) {
        width: 15rem;
    }
`;

export const Menu = styled.ul`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: white;
        flex-direction: column;
        justify-content: center;
        transform: translateX(100%);
        transition: transform 0.75s ease;
        background-color: rgb(11, 11, 15);

        ${(props) =>
            props.menuOpen === "true" &&
            `
            transform: none;
        `};
    }

    ${(props) =>
        props.transparent === "true" &&
        `
            a {
                color: rgba(255, 255, 255, .75);

                &:hover {
                    color: #F5D776;
                }
            }
        `};
`;

export const MenuItem = styled.li`
    position: relative;
    list-style: none;

    &:hover {
        & > div:last-child {
            height: 25rem;
        }
    }
`;

export const MenuDropdownWrapper = styled.div`
    position: absolute;
    top: 1.05rem;
    left: -4rem;
    width: 20rem;
    height: 0;
    padding-top: 3.6rem;
    transition: opacity 0.75s ease, transform 0.75s ease;
    overflow: hidden;
    transition: height 0.5s ease;

    @media only screen and (max-width: 1024px) {
        display: none;
    }
`;

export const MenuDropdown = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 1rem 2rem;
    background-color: white;
    border: 1px solid rgba(11, 11, 15, 0.1);
    transition: transform 0.5s ease;
`;

export const MenuDropdownItem = styled(Link)`
    display: block;
    text-decoration: none;
    color: rgba(11, 11, 15, 0.65) !important;
    padding: 1.3rem 0;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    transition: color 0.5s ease;

    &:not(:last-child) {
        border-bottom: 1px solid rgba(11, 11, 15, 0.1);
    }

    &:hover {
        color: rgb(11, 11, 15) !important;
    }
`;

export const MenuLinkExternal = styled.a`
    display: inline-block;
    font-size: 1.2rem;
    margin-right: 3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${(props) =>
        props.activeitem === "true"
            ? "rgb(11, 11, 15)"
            : "rgba(46, 47, 46, 0.75)"};
    transition: color 0.5s ease;
    text-decoration: none;

    @media only screen and (max-width: 1024px) {
        font-size: 1.6rem;
        margin-right: 0;
        margin-bottom: 2rem;
        color: ${(props) =>
            props.activeitem === "true"
                ? "#F5D776"
                : "rgba(255, 255, 255, 0.75)"};
    }

    &:hover {
        color: rgba(46, 47, 46, 1);
    }
`;

export const MenuLink = styled(Link)`
    display: inline-block;
    font-size: 1.2rem;
    margin-right: 3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: ${(props) =>
        props.activeitem === "true"
            ? "rgb(11, 11, 15)"
            : "rgba(46, 47, 46, 0.75)"};
    transition: color 0.5s ease;
    text-decoration: none;

    @media only screen and (max-width: 1024px) {
        font-size: 1.6rem;
        margin-right: 0;
        margin-bottom: 2rem;
        color: ${(props) =>
            props.activeitem === "true"
                ? "#F5D776"
                : "rgba(255, 255, 255, 0.75)"};
    }

    &:hover {
        color: rgba(46, 47, 46, 1);
    }
`;

export const MenuButton = styled.a`
    font-size: 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    color: rgb(11, 11, 15);
    border: 1px solid rgb(11, 11, 15);
    padding: 1.5rem;
    transition: all 0.5s ease;

    @media only screen and (max-width: 1024px) {
        font-size: 1.6rem;
        margin-top: 1rem;
        padding: 1.75rem 3rem;
        border-color: white;
        color: white;
    }

    ${(props) =>
        props.transparent === "true" &&
        `
            color: white;
            border: .5px solid white;
        `};

    &:hover {
        color: white;
        transform: translateY(-5px);
        background-color: rgb(11, 11, 15);

        ${(props) =>
            props.transparent === "true" &&
            `
                color: rgb(11, 11, 15) !important;
                background-color: white;
        `};
    }
`;

export const MenuMobileContainer = styled.button`
    width: 5rem;
    height: 5rem;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(2rem, -7.5px);
    position: relative;
    z-index: 1000;
    cursor: pointer;
`;

export const MenuMobile = styled.div`
    transition: all 0.5s ease;
    border: none;
    width: 3rem;
    border: 0.5px solid rgb(11, 11, 15);
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 7.5px;
        left: -1px;
        width: 3rem;
        border: 0.5px solid rgb(11, 11, 15);
        transition: all 0.5s ease;
    }

    ${(props) =>
        props.transparent === "true" &&
        `
        border-color: white;

        &:after {
            border-color: white;
        }
    `};

    ${(props) =>
        props.menuOpen === "true" &&
        `
        border-color: white;
        transform: rotateZ(-45deg);

        &:after {
            border-color: white;
            transform: rotateZ(-90deg) translate(8px, 1px);
        }
    `};

    @media only screen and (min-width: 1025px) {
        display: none;
    }
`;

export const MenuClose = styled.button`
    @media only screen and (min-width: 1025px) {
        display: none;
    }
`;

export const MenuSocials = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 2rem;
    }
`;

export const MenuSocial = styled.a`
    margin-right: 1rem;

    &:first-child {
        transform: scale(0.9);
    }

    &:last-child {
        margin-right: 3rem;
        transform: translateY(1px);

        @media only screen and (max-width: 1024px) {
            margin-right: 0;
        }
    }
`;
