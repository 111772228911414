import { Link } from "react-router-dom";

// Styles
import { Container } from "../sc/scLayout";
import {
    AboutContainer,
    AboutMain,
    AboutHeading,
    AboutDivider,
    AboutText,
    AboutImgContainer,
    AboutImg,
} from "../sc/scAbout";

// Images
import Logo from "../assets/footer-logo.png";

const CONTENT = {
    heading: "Ne yapıyoruz?",
    text: (
        <>
            Ayar Denetim olarak;
            <br />
            TSE'den <b>HYB (Hizmet Yeri Belgesi)</b> almak ve{" "}
            <b>yetkili servis</b> olmak için,
            <br />
            OSB (Organize Sanayi Bölgesi) <b>Yönetimleri</b> için,
            <br />
            İSG (İş Sağlığı ve Güvenliği) <b>uygunluk belgeleri</b> için,
            <br />
            ÇSGB <b>(Çalışma ve Sosyal Güvenlik Bakanlığı)</b> denetimleri için
            ZORUNLU olan;
            <br />
             <br />• <b>Topraklama,</b> doğalgaz topraklaması, tank
            topraklaması,
            <br />
            • Katodik koruma,
            <br />• <b>Paratoner,</b>
            <br />
            • Termal görüntüleme ile ısınma tespiti,
            <br />• <b>Elektrik tesisatı ölçümlerinizi</b> gerçekleştiriyoruz ve
            uygunluk raporlarınızı hazırlıyoruz.
            <br />
            <br />
            Mevzuata hakim, güncel yönetmelik ve standartları takip eden, sektör
            deneyimi olan, <b>Elektrik Mühendisleri Odası (EMO)'na</b> kayıtlı
            ve gerekli belgelere (
            <b>topraklama belgesi, elektrik iç tesisat belgesi,</b> Bakanlık/
            <b>EKİPNET</b> Belgesi) sahip <b>tecrübeli mühendis</b> kadromuzla{" "}
            <b>sizlere de yardımcı olmak isteriz.</b>
            <br />
            <br />
            Size özel indirimli fiyatlarımızdan yararlanmak için hemen{" "}
            <Link to="/iletisim">tıklayın!</Link> <br />
            Diğer hizmetlerimiz için tanıtım kataloğumuzu{" "}
            <Link to="/katalog">inceleyebilirsiniz.</Link>
            <br />
        </>
    ),
};

const WhatWeDoText = () => {
    return (
        <Container>
            <AboutContainer>
                <AboutMain>
                    <AboutHeading>{CONTENT.heading}</AboutHeading>
                    <AboutDivider />
                    <AboutText>{CONTENT.text}</AboutText>
                </AboutMain>
                <AboutImgContainer>
                    <AboutImg src={Logo} />
                </AboutImgContainer>
            </AboutContainer>
        </Container>
    );
};

export default WhatWeDoText;
