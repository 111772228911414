import {
    HeroWrapper,
    HeroImg,
    HeroHeading,
    HeroButton,
    HeroAnimated,
} from "../sc/scHeroSlider";

// GSAP
import gsap from "gsap";
import { useEffect } from "react";

// Images
import HeroImage1 from "../assets/home-hero-1.png";
import HeroImage2 from "../assets/home-hero-2.png";

const HeroSlider = () => {
    useEffect(() => {
        let tl = gsap.timeline();
        tl.to(".hero-img-1", { duration: 0, scale: 1.03 })
            .to(".hero-img-1", { duration: 7.5, scale: 1.2 })
            .to(".hero-heading-1", { duration: 0, display: "block" }, "-=7.5")
            .to(
                [".hero-heading-animated-1", ".hero-button-1"],
                {
                    duration: 1.5,
                    opacity: 1,
                    yPercent: -200,
                    stagger: 0.1,
                },
                "-=7.5"
            )
            .to(".hero-img-1", { duration: 1.5, opacity: 0 }, "-=3")
            .to(
                ".hero-img-2",
                {
                    duration: 7.5,
                    scale: 1.2,
                },
                "-=3.5"
            )
            .to(
                [".hero-heading-animated-1", ".hero-button-1"],
                {
                    duration: 1.5,
                    yPercent: -400,
                    opacity: 0,
                    stagger: 0.1,
                    onComplete: () => {
                        gsap.set(
                            [".hero-heading-animated-1", ".hero-button-1"],
                            { yPercent: 200 }
                        );

                        gsap.set(".hero-heading-1", { display: "none" });
                    },
                },
                "-=7.5"
            )
            .to(".hero-heading-2", { duration: 0, display: "block" }, "-=6.25")
            .to(
                [".hero-heading-animated-2", ".hero-button-2"],
                {
                    duration: 1.5,
                    opacity: 1,
                    yPercent: -200,
                    stagger: 0.1,
                },
                "-=6.25"
            )
            .to(
                [".hero-heading-animated-2", ".hero-button-2"],
                {
                    duration: 1.5,
                    yPercent: -400,
                    opacity: 0,
                    stagger: 0.1,
                    onComplete: () => {
                        gsap.set(
                            [".hero-heading-animated-2", ".hero-button-2"],
                            { yPercent: 200 }
                        );
                    },
                },
                "-=3"
            )
            .to(
                ".hero-img-1",
                {
                    duration: 0,
                    scale: 1,
                },
                "-=2.5"
            )
            .to(
                ".hero-img-1",
                {
                    duration: 0.5,
                    opacity: 1,
                    onComplete: () => {
                        tl.restart();
                    },
                },
                "-=2"
            )
            .to(
                ".hero-img-1",
                {
                    duration: 7.5,
                    scale: 1.2,
                },
                "-=2.1"
            );

        return () => {
            tl.kill();
        };
    }, []);

    return (
        <HeroWrapper>
            <HeroAnimated></HeroAnimated>
            <HeroImg className="hero-img-2" src={HeroImage1} />
            <HeroImg className="hero-img-1" src={HeroImage2} />
            <HeroHeading className="hero-heading-1">
                <h1 className="hero-heading-animated-1">AYAR DENETİM A TİPİ</h1>
                <h1 className="hero-heading-animated-1">
                    AKREDİTE MUAYENE KURULUŞU
                </h1>
                <HeroButton className="hero-button-1" to="/katalog">
                    KATALOG'A GÖZAT
                </HeroButton>
            </HeroHeading>
            <HeroHeading className="hero-heading-2">
                <h1 className="hero-heading-animated-2">ELEKTRİKSEL</h1>
                <h1 className="hero-heading-animated-2">PERİYODİK ÖLÇÜMLER</h1>
                <HeroButton className="hero-button-2" to="/hizmetler">
                    HİZMETLERİMİZ
                </HeroButton>
            </HeroHeading>
        </HeroWrapper>
    );
};

export default HeroSlider;
