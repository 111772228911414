import { ServiceShortRenderer } from "./components/Service";

// Images
import ServiceContentImg1 from "./assets/service-1.png";
import ServiceContentImg2 from "./assets/service-2.png";
import ServiceContentImg3 from "./assets/service-3.png";
import ServiceContentImg4 from "./assets/service-4.png";
import ServiceContentImg5 from "./assets/service-5.jpg";
import ServiceContentImg6 from "./assets/service-6.png";
import ServiceContentImg7 from "./assets/service-7.png";
import ServiceContentImg8 from "./assets/service-8.png";
import ServiceContentImg9 from "./assets/service-9.png";
import ServiceContentImg10 from "./assets/service-10.png";
import ServiceContentImg12 from "./assets/service-12.png";
import ServiceContentImg13 from "./assets/service-13.png";
import ServiceContentImg14 from "./assets/service-14.png";
import ServiceContentImg15 from "./assets/service-15.png";

export const ABOUT_CONTENT = [
    {
        name: "biz-kimiz",
        heading: "BİZ KİMİZ",
    },
    {
        name: "ne-yapiyoruz",
        heading: "Ne Yapıyoruz",
    },
    {
        name: "kalite-politikasi",
        heading: "Kalite Politikası",
    },
    {
        name: "tarafsizlik-bagimsizlik-gizlilik-taahhutnamesi",
        heading: "Tarafsızlık Bağımsızlık ve Gizlilik Taahhütnamesi",
    },
];

export const SERVICE_SHORT_CONTENT = [
    {
        name: "kacak-akim-rolesi-testi",
        img: ServiceContentImg1,
        heading: "KAÇAK AKIM RÖLESİ TESTİ",
        text:
            "Kaçak Akım Rölesi, elektrik devrelerinde toprak kaçaklarına karşı devre elemanlarını, devreye bağlı cihazları koruyan ve hata esnasında dolaylı dokunmaya karşı da canlıları korumaya yarayan ekipmanlardır.\n\n" +
            "Kaçak akım röleleri periyodik olarak bakım gerektiren ekipmanlardandır. Nemli ve tozlu ortamlarda oluşabilecek arızalara karşı ve çalışma şartlarından ötürü oluşabilecek deformasyonlara karşı bakım ve kontrollerinin yapılması gerekir.\n\n" +
            "Elektrik İç Tesisleri Yönetmeliği Madde 18’e göre Kaçak Akım Rölesi TT sistemler için zorunludur.\n\n" +
            "Elektrik Tesislerinde Topraklamalar Yönetmeliği Madde 10.c.6.3.i’e göre hata akımı koruma düzenin arkasında meydana getirilen bir hata akımıyla çalıştığı ispatlanmalıdır.",
    },
    {
        name: "toprak-ozgul-direnci-olcumu",
        img: ServiceContentImg2,
        heading: "TOPRAK ÖZGÜL DİRENCİ ÖLÇÜMÜ",
        text:
            "Toprak Özgür Direnci, toprağın özgül elektrik direnci olup, 1 metre küp toprağın direnci olarak ifade edilir. Birimi ohm.metredir. Toprak özgül direncine yapıların topraklama sistemlerinin oluşturulabilmesi için ihtiyaç duyulmaktadır.\n\n" +
            "Toprak yayılma direnci veya topraklama empedansını önceden belirlemek amacıyla toprak özdirencinin ölçülmesi, bu direncin çeşitli derinlikler için tespit edilmesini sağlayan “dört sonda yöntemi” ile yapılmaktadır. (Elektrik Tesislerinde Topraklamalar Yönetmeliği Ek_N)\n\n" +
            "Topraklama sisteminin tasarımı yapılmadan önce toprak özgül direnci ölçülerek uygun topraklama tesisi tasarlanmalıdır. 03 Aralık 2003 tarihli Elektrik İç Tesisleri Proje Hazırlama Yönetmeliği’nin 10.c.5.i.1 maddesi projelere başlamadan önce toprak özdirencinin belirlenmesi şarttır.",
    },
    {
        name: "cevrim-empedansi-olcumu",
        img: ServiceContentImg3,
        heading: "ÇEVRİM EMPEDANSI ÖLÇÜMÜ",
        text: "Besleme kaynağının, arıza noktasına kadar gerilimli iletkeni ve kaynakla arıza noktası arasındaki koruma iletkenini içeren arıza çevriminin empedansıdır.\n\nTN sistemlerde dolaylı dokunmaya karşı koruma için aşırı akım düzenleri kullanılıyorsa, proje aşamasında seçilen iletken kesitlerinin her açma zamanı için gerekli olan akıma uygun olup, olmadığının kontrolü gerekir. Dolaylı dokunmada koruma için ana iletken ile koruma iletkeni arasındaki ve ana iletken ile PEN iletkeni arasındaki çevrim empedansı tespit edilmelidir.\n\nElektrik Tesislerinde topraklamalar Yönetmeliği Madde 10 6.2’de belirtilmiş olduğu üzere, TN sistemlerde topraklama geçiş direncinin belirlenmesi yeterli değildir, çevrim empedansının ölçülmesi de gerekmektedir.",
    },
    {
        name: "elektrik-ic-tesisat-kontrolu",
        img: ServiceContentImg4,
        heading: "ELEKTRİK İÇ TESİSAT KONTROLÜ",
        text: "Mevzuata göre mevcut elektrik tesisatlarında, yeni kurulacak tesislerde ve kurulu tesislerin tamamen değiştirilmesi durumunda Elektrik İç Tesisat Kontrolü gerçekleştirilmelidir.\n\nİş yeri bina eklentilerinde alınacak sağlık ve güvenlik önlemlerine ilişkin yönetmelik Ek-1 ve TS HD 60364-4-43 standardına göre kontrol edilmeli ve kayıt altına alınmalıdır.\n\nAyar Denetim, ilgili standartlara ve yönetmeliklere göre elektrik tesisatınızın ölçüm ve kontrollerini gerçekleştirerek Elektrik Pano Görsel Kontrollerini, Elektrik Pano Fonksiyon Kontrollerini, Termal Görüntüleme ile tesisatın sağlıklı çalışıp çalışmadığını kontrol ederek Elektrik İç Tesisat Kontrolü’nü gerçekleştirmektedir.",
    },
    {
        name: "sureklilik-testi",
        img: ServiceContentImg5,
        heading: "SÜREKLİLİK TESTİ",
        text: "Süreklilik testi, iletkenin bütünlüğünü kanıtlamak için iki uç arasında sürekliliğin tespit edilmesidir.\n\nTopraklama tesisatlarında süreklilik testleri; koruma iletkenlerinin, ana ve yardımcı eş potansiyel baralarının bağlantılarının sağlamlığını tespiti için yapılır.\n\nElektrik Tesislerinde Topraklamalar Yönetmeliği’nin Topraklama Tesislerinde Muayene, Ölçme ve Denetleme kısmı Madde 10-c2’ye göre gerçekleştirilmesi gereken ölçümlerdendir. Ayar Denetim, Elektrik Tesislerinde Topraklamalar Yönetmeliği’nin öngördüğü şekilde süreklilik ölçümlerini gerçekleştirmekte ve raporlamaktadır. ",
    },
    {
        name: "yalitim-direnci-olcumu",
        img: ServiceContentImg6,
        heading: "YALITIM DİRENCİ ÖLÇÜMÜ",
        text: "Elektrikli tüm ekipmanlar için gerilimli olan bölümler ile normalde gerilim altında olmayan kısımların yalıtımının sağlanması gerekir. Enerji taşımayan bölümlere temas eden canlıların zarar görmelerini önlemek için standartlarda belirtilen yalıtım testlerini yapmalı ve gerekli tüm güvenlik önlemleri alınmalıdır.\n\nİzole bir ekipmanın yani yalıtkanın elektrik akımına karşı gösterdiği dirence yalıtım direnci denilir. Elektrikli ekipmanlarda yalıtım direnci ölçümü; iletken ve izole kısımlar ile birbirinden izole iletken kısımların arasına standartların öngördüğü seviyede gerilim uygulanarak elde edilen izolasyon direnci ölçüm sonuçlarına dayanır.\n\nAyar Denetim, Yalıtım Direnci konusunda ilgili standart ve yönetmelikler doğrultusunda uzman kadrosu ile ölçüm ve incelemeleri gerçekleştirmekte ve raporlarını hazırlamaktadır.",
    },
    {
        name: "zemin-yalitkanlik-direnci-olcumu",
        img: ServiceContentImg7,
        heading: "ZEMİN YALITKANLIK DİRENCİ ÖLÇÜMÜ",
        text: "Doğrudan dokunmaya karşı alınacak tedbirlerin en önemlilerinden biri çalışma alanının çalışma gerilimine göre elektriksel yalıtımının(izolasyonun) sağlanmasıdır.\n\nTesislerin çoğunda zemin ve temas alanında yer alan duvar ve benzeri çevresel faktörler, yalıtım malzemesi (izolasyon halısı vb.) ile kaplanarak önlemler alınmaktadır. Zemin ve çevresel faktör yalıtımına yönelik olarak kullanılan malzemelerin, çalışma gerilimi dikkate alınarak, uygunluğu zemin yalıtkanlık direnci ölçümlerinin yapılması gerektiği Elektrik İç Tesisleri Yönetmeliği Madde 48’de belirtilmiştir.\n\nAyar Denetim, konusunda uzman personeli ile Zemin Yalıtkanlık Direnci ölçümlerini ilgili standart ve yönetmeliklere uygun şekilde gerçekleştirmekte ve raporlarını hazırlamaktadır.",
    },
    {
        name: "yildirimdan-korunma-sistemleri-olcumu-ve-kontrolu",
        img: ServiceContentImg8,
        heading: "YILDIRIMDAN KORUNMA\nSİSTEMLERİNİN ÖLÇÜMÜ VE KONTROLÜ",
        text: "Yıldırım çarpmalarının etkileri, doğrudan ve dolaylı yakın alan ve uzak alan etkileri olarak ele alınabilir. Doğrudan etkiler, yıldırım darbesinin iletilmesi esnasında akan akımdan kaynaklanır.\n\nDolaylı yakın alan etkileri, yıldırım akımı ve oluşan elektromanyetik alandan kaynaklanan geçici olaylara bağlıdır. Bu yolla indüklenen gerilimler, sistemlerde yalıtımın bozulmasına ve atlamaların meydana gelmesine sebep olabilir.\n\nUzak alan etkileri, elektromanyetik alan etkileri biçiminde görülürler ve diğerlerine göre şiddetleri oldukça düşüktür. Yıldırımdan korunma tesisatı, yıldırım darbesini güvenli bir yolla toprağa iletip doğrudan ve dolaylı etkilerini ortadan kaldırmak amacıyla kurulur. Tesisat aktif veya pasif bir sistemin düşük dirençli bir elektrotla topraklanmasıyla tesis edilir.",
        more: {
            img: ServiceContentImg9,
            text: "Etkili bir koruma yapılabilmesi için yıldırımdan korunma sistemleri tesis edilirken; tesisin yıldırımdan korunma seviyesi tespiti hesabı, yıldırımdan korunma yollunun seçimi, yıldırımdan korunma yönteminin standartlara uygun olarak tespit edilmesi gerekmektedir.\n\nPrensip olarak yıldırımdan korunma sisteminin, yıldırımın zararlı fiziksel etkilerine karşı koruma yapması beklenir. Fakat kontrolü gerçekleştirilmeyen tesisatlar risk teşkil eder. Bu sebeple periyodik bakımların yanında periyodik ölçüm ve kontrollerin de yaptırılması hayati önem arz etmektedir.\n\nAyar Denetim, Yıldırımdan Korunma Sistemlerinin Muayenesi konusunda TS EN 62305-1, TS EN 62305-3, NFC 17102 Standartları’na göre uzman kadrosu ile ölçüm ve kontroller gerçekleştirmekte ve raporlarını hazırlamaktadır.",
        },
    },
    {
        name: "topraklama-direnci-olcumu",
        img: ServiceContentImg10,
        heading: "TOPRAKLAMA DİRENCİ ÖLÇÜMÜ",
        text: (
            <div>
                Topraklama: Elektrikli işletme araçlarının aktif olmayan metal
                kısımlarının bir iletken yardımıyla toprakla birleştirilmesidir.
                Topraklama; herhangi bir elektriksel cihaz veya ekipmanda
                yalıtım hatası oluştuğunda insan ve diğer canlıların yüzeye
                teması halinde maruz kalacağı potansiyel fark nedeniyle elektrik
                çarpılmasına karşı tedbir amacı taşır. Bu sebeple ölçüm
                periyotlarına mutlaka dikkat edilmelidir.
                <br />
                <br />
                Enerji Tabi Kaynaklar Bakanlığı tarafından hazırlanıp 21.08.2001
                tarih ve 24500 sayılı Resmi Gazetede yayınlanarak, yürürlüğe
                giren, “Elektrik Tesislerinde Topraklamalar Yönetmeliği Ek-P`de
                çeşitli topraklama tesislerinde işletme dönemi içindeki muayene,
                ölçme ve denetlemelere ilişkin önerilen periyotları verilmiştir.
                <br />
                <br />
                Bunlar;
                <ul>
                    <li>Elektrik üretim, iletim, dağıtım tesisleri, 2 yıl</li>
                    <li>Sabit işletme elemanları 1 yıl</li>
                    <li>Enerji Nakil ve dağıtım hatları 5 yıl</li>
                    <li>Yeri değişebilen işletme elemanları 6 ay</li>
                    <li>
                        Sanayi tesisleri ve iş merkezleri direnç değerleri 1 yıl
                    </li>
                    <li>
                        Topraklama tesisleri ile ilgili diğer kontroller 2 yıl
                    </li>
                    <li>
                        Parlayıcı, patlayıcı, tehlikeli zararlı maddelerle
                        çalışabilen işyerleri ve ıslak ortamlarda çalışılan
                        işyerleri için kontrol periyotları 1 yılı aşamaz.
                    </li>
                </ul>
            </div>
        ),
    },
];

export const SERVICE_LONG_CONTENT = [
    {
        name: "termal-kamera-olcumu",
        images: [ServiceContentImg12, ServiceContentImg13],
        heading: "TERMAL KAMERA ÖLÇÜMÜ",
        text: (
            <div>
                Yöntemsel olarak gözle tespit edilemeyen IR enerjiyi (ısıyı)
                esas alarak ısı farklılıklarına göre görüntüleme yapan termal
                görüntüleme cihazları elektrik tesisatlarının ısınma
                problemlerini tespit etmek için yaygın olarak kullanılmaktadır.
                Test prosedürleri temassızdır ve çabucak uygulanabilir.
                <br />
                <br />
                Elektrik iletimi sırasında akım geçişi ile direnç üzerinde
                enerji ısı olarak harcanır. Bu durumda elektrik iletiminde
                özellikle kontak noktalarında ısı artışları o nokta için bir
                direnç olduğunun göstergesidir. Kabloların birleşme
                noktalarında, kontak noktalarında, baraların bağlantı
                noktalarında, sigorta klemenslerinde, termik şalterlerin
                bağlantı noktalarında bu dirençler ısınmalara sebep olurlar. Bu
                ısınmalar termal kamera ile görüntülenerek noktasal tespitler
                yapılabilir. Yapılan bu tespitler sonucunda planlı zamanlarda
                probleme sebep olan olumsuz durumlar ortadan kaldırılabilir.
                <br />
                <br />
                Ayar Denetim, ilgili standartlara ve yönetmeliklere göre
                elektrik tesisatınızın ölçüm ve kontrollerini gerçekleştirerek
                Elektrik Pano Görsel Kontrollerini, Elektrik Pano Fonksiyon
                Kontrollerini, Termal Görüntüleme ile tesisatın sağlıklı çalışıp
                çalışmadığını kontrol ederek Elektrik İç Tesisat Kontrolü’nü
                gerçekleştirmektedir.
                <br />
                <br />
                Ayar Denetim, uzman ekibiyle ilgili standartlara ve
                yönetmeliklere göre elektrik tesisatınızın Termografik
                Muayenesini gerçekleştirerek tesisatın sağlıklı çalışıp
                çalışmadığını kontrol ederek Elektrik İç Tesisat Kontrolü’nü
                gerçekleştirmektedir.
            </div>
        ),
    },
    {
        type: true,
        name: "enerji-analizi-ve-harmonik-olcumu",
        images: [ServiceContentImg14],
        heading: "ENERJİ ANALİZİ VE HARMONİK ÖLÇÜMÜ (*)",
        text: (
            <div>
                Güç sistemlerinde, akım-gerilim eğrilerinin sinüzoidal biçimli
                sabit frekans ve genlikte olması istenir. Enerji Analizi ve
                Harmonik Ölçümünde yapılan ölçümler ile şebekenin istenen
                kalitede olup olmadığı, şebekede istenen kısımlara bağlanan
                cihazlarla ölçülerek kayıt altına alınır ve kayıtlar incelenerek
                bozulmalar tespit edilir.
                <br />
                <br />
                Enerjinin belirli parametrelerde istenilen değerlere ulaşması
                kaliteyi artırır. Bu kaliteyi etkileyecek çeşitli faktörler
                vardır. Bunların en önemlisi harmoniklerdir.
                <br />
                <br />
                Harmonik akım veya gerilimin saf sinüs eğrisinden
                uzaklaşmasıdır. Başlıca harmonik nedenlerini sıralarsak;
                <ul>
                    <li>Elektronik balastlar</li>
                    <li>Ark ocakları ve elektroliz</li>
                    <li>Kesintisiz güç kaynakları</li>
                    <li>AC Hız kontrol cihazlar.</li>
                    <li>
                        Güç elektroniği elemanları (Statik AC/DC güç
                        dönüştürücüleri, doğrultucular)
                    </li>
                </ul>
                Harmonikler sistemlerde elektrik güç sistemlerinde ek kayıpların
                oluşmasına, güç üretimi, iletimi ve dağıtımında kayıplara,
                gerilim düşümünün artmasına, toprak kısa devre akımlarının
                yükselmesine PLC ve otomasyon sistemlerinde arızalara,
                elektronik kartların yanmasına, röle sinyallerinin bozulmasına
                kompanzasyon sistemlerindeki kondansatörlerin aşırı
                zorlanmasına, koruma sistemlerinin hatalı açmasına,
                mikroişlemcilerin hatalı çalışmasına , motorlarda ek gürültü ve
                yüklenmeye sebep olmasına sebep olmakta ve işletme koşullarına
                zarar vermektedir.
                <br />
                <br />
                Uluslararası standartlar gereği gerilim için harmonik bozunumu
                %3, akım için %5 olarak belirlenmiştir. Harmonik ölçümünün her
                tesisin kaliteli bir elektrik enerjisine sahip olabilmesi için
                düzenli olarak yapılması gerekir. Bu ölçümlerin sonuçları
                doğrultusunda gerekli optimizasyonun yapılması önemlidir.
                <br />
                <br />
                Ayar Denetim, konusunda uzman personeli ile Enerji Analizi ve
                Harmonik Ölçümlerini ilgili standart ve yönetmeliklere uygun
                şekilde gerçekleştirmekte ve raporlarını hazırlamaktadır.
                <br />
                <br />
                <strong>*Kapsam dışı olarak gerçekleştirilmektedir.</strong>
            </div>
        ),
    },
    {
        type: true,
        imgSlider: ServiceContentImg15,
        name: "katodik-koruma-olcumu",
        heading: "KATODİK KORUMA ÖLÇÜMÜ",
        text: (
            <div>
                Katodik koruma, metal yapıları korozyona karşı korumada etkili
                bir yöntemdir.
                <br />
                Katodik koruma iki metotla uygulanır.
                <div className="methods">
                    <div className="method">
                        <div className="method-header">
                            <div className="method-icon">1</div>
                            <h1>
                                Galvanik <br />
                                Anot Metodu
                            </h1>
                        </div>
                        <p>
                            İşletme ve bakımları oldukça kolaydır. Korumayı
                            sağlayan anotlar devre dışı kalmadığı sürece bakım
                            gerektirmez. Anotların devre dışı kalmadığını tespit
                            etmek için ölçümlerin düzenli olarak
                            gerçekleştirilmesi gerekir.
                        </p>
                    </div>
                    <div className="method">
                        <div className="method-header">
                            <div className="method-icon">2</div>
                            <h1>
                                Dış Akım Kaynaklı
                                <br />
                                Metod (Trafo / Redresör)
                            </h1>
                        </div>
                        <p>
                            Dış akım kaynaklı metod için trafo/redresör
                            sisteminin bakımlarının gerçekleştirilmesi aynı
                            zamanda da katodik koruma ölçümlerinin
                            gerçekleştirilmesi gerekmektedir.
                        </p>
                    </div>
                </div>
                <hr className="divider" />
                <ServiceShortRenderer
                    img={ServiceContentImg15}
                    text={
                        <>
                            <br />
                            Dış akım kaynaklı metod için trafo/redresör
                            sisteminin bakımlarının gerçekleştirilmesi aynı
                            zamanda da katodik koruma ölçümlerinin
                            gerçekleştirilmesi gerekmektedir.
                            <br />
                            <br />
                            Türk standartları enstitüsü TS 5141 EN 12954 Katodik
                            koruma- Gömülü veya suya daldırılmış metalik yapılar
                            için boru hatları için prensipler ve uygulama
                            standardında madde 10.3,3,1 katodik koruma
                            sisteminin muayenesi çizelge 2’de Galvanik Anot
                            İstasyonlarında yıllık veya işletme gerektiriyorsa
                            daha sık yapılmalıdır denmektedir. Katodik Koruma
                            ölçüm ve kontrollerin yılda iki defa (yaz ve kış
                            aylarında) yapılması tavsiye edilir.
                            <br />
                            <br />
                            Ayar Denetim, uzman personeli ile TS EN 13509 ve TS
                            EN 13636 Standartlarına göre ölçüm ve incelemeleri
                            gerçekleştirmekte ve raporlarını hazırlamaktadır.
                        </>
                    }
                />
            </div>
        ),
    },
];
