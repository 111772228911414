import styled from "styled-components";

export const HeroWrapper = styled.div`
    margin-top: 11rem;
    height: 55rem;
    position: relative;
    overflow: hidden;

    @media only screen and (max-width: 1024px) {
        & > div {
            display: flex;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 600px) {
        height: 45rem;
    }
`;

export const HeroBackground = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const HeroContainer = styled.div`
    position: absolute;
    width: 130%;
    max-width: 53rem;
    height: 55rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);

    @media only screen and (max-width: 600px) {
        height: 45rem;
    }
`;

export const HeroImg = styled.img`
    width: 4.2rem;
    margin-bottom: 2rem;
`;

export const HeroText = styled.h1`
    color: white;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
    padding: 0 2rem;
    line-height: 1.7;
    letter-spacing: 1px;
`;
