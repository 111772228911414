// Styles
import { Container } from "../sc/scLayout";
import {
    ContactMapWrapper,
    ContactWrapper,
    ContactContainer,
    ContactMain,
    ContactLogo,
    ContactHeading,
    ContactItem,
    ContactItemImg,
    ContactItemText,
} from "../sc/scContact";

// Images
import Pin from "../assets/pin.svg";
import Mail from "../assets/mail.svg";
import Phone from "../assets/phone.svg";
import WorkingHours from "../assets/working-hours.svg";
import TurkakLogo from "../assets/turkak-logo.png";

// API KEY
const API_KEY = "AIzaSyCkPWIrdCrHdkJmABbnvO0lf49Fetr3boc";

// Constants
export const CONTACT = [
    {
        img: Pin,
        text: "Mimar Sinan Mah. Üsküdar Cad. YEDPA\nNo: 1 F İç Kapı No: 289 Ataşehir / İSTANBUL",
    },
    {
        img: Mail,
        text: <a href="mailto:info@ayardenetim.com">info@ayardenetim.com</a>,
    },
    {
        img: Phone,
        text: "0216 471 45 75",
    },
    {
        img: WorkingHours,
        text: "Pazartesi - Cuma\n09:00 - 18:00",
    },
];

const ContactMap = ({ address }) => {
    return (
        <ContactMapWrapper>
            <iframe
                title="Location"
                width="600"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${address}`}
            ></iframe>
        </ContactMapWrapper>
    );
};

const ContactDetails = () => {
    return (
        <ContactWrapper>
            <Container>
                <ContactContainer>
                    <ContactMain>
                        <ContactLogo src={TurkakLogo} />
                        <ContactHeading>
                            AYAR DENETİM TEST, ÖLÇÜM VE PERİYODİK KONTROL
                            HİZMETLERİ LIMITED ŞİRKETİ
                        </ContactHeading>
                        {CONTACT.map((contactItem, i) => (
                            <ContactItem key={i}>
                                <ContactItemImg src={contactItem.img} />
                                <ContactItemText>
                                    {contactItem.text}
                                </ContactItemText>
                            </ContactItem>
                        ))}
                    </ContactMain>
                    <ContactMap address="Mimar Sinan Mah. Üsküdar Cad. YEDPA No: 289 Ataşehir / İSTANBUL" />
                </ContactContainer>
            </Container>
        </ContactWrapper>
    );
};

export default ContactDetails;
