import { Link } from "react-router-dom";

// Swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Page Flip
import HTMLFlipBook from "react-pageflip";

// Styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Container } from "../sc/scLayout";
import { CatalogWrapper, DecorationLeft, Page } from "../sc/scCatalog";
import { SliderWrapper } from "../sc/scSlider";

// Images
import CatalogImg0 from "../assets/catalog-0.png";
import CatalogImg1 from "../assets/catalog-1.png";
import CatalogImg2 from "../assets/catalog-2.png";
import CatalogImg3 from "../assets/catalog-3.png";
import CatalogImg4 from "../assets/catalog-4.png";
import CatalogImg5 from "../assets/catalog-5.png";
import CatalogImg6 from "../assets/catalog-6.png";
import CatalogImg7 from "../assets/catalog-7.png";
import CatalogImg8 from "../assets/catalog-8.png";
import CatalogImg9 from "../assets/catalog-9.png";
import CatalogImg10 from "../assets/catalog-10.png";
import CatalogImg11 from "../assets/catalog-11.png";
import CatalogImg12 from "../assets/catalog-12.png";
import CatalogImg13 from "../assets/catalog-13.png";
import CatalogImg14 from "../assets/catalog-14.png";
import CatalogImg15 from "../assets/catalog-15.png";
import CatalogImg16 from "../assets/catalog-16.png";
import CatalogImg17 from "../assets/catalog-17.png";
import CatalogImg18 from "../assets/catalog-18.png";

const Catalog = () => {
    return (
        <CatalogWrapper>
            <DecorationLeft></DecorationLeft>
            <HTMLFlipBook
                width={500}
                height={700}
                showCover={true}
                style={{ backgroundColor: "#16161C" }}
                className="animated"
            >
                <Page src={CatalogImg0} />
                <Page src={CatalogImg1} />
                <Page src={CatalogImg2} />
                <Page src={CatalogImg3} />
                <Page src={CatalogImg4} />
                <Page src={CatalogImg5} />
                <Page src={CatalogImg6} />
                <Page src={CatalogImg7} />
                <Page src={CatalogImg8} />
                <Page src={CatalogImg9} />
                <Page src={CatalogImg10} />
                <Page src={CatalogImg11} />
                <Page src={CatalogImg12} />
                <Page src={CatalogImg13} />
                <Page src={CatalogImg14} />
                <Page src={CatalogImg15} />
                <Page src={CatalogImg16} />
                <Page src={CatalogImg17} />
                <Link to="/">
                    <Page src={CatalogImg18} />
                </Link>
            </HTMLFlipBook>
            <SliderWrapper>
                <Container>
                    <SliderWrapper>
                        <Swiper spaceBetween={20} slidesPerView={1}>
                            <SwiperSlide>
                                <img src={CatalogImg0} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg1} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg2} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg3} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg4} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg5} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg6} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg7} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg8} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg9} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg10} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg11} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg12} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg13} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg14} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg15} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg16} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={CatalogImg17} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link to="/">
                                    <img src={CatalogImg18} alt="" />
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                    </SliderWrapper>
                </Container>
            </SliderWrapper>
        </CatalogWrapper>
    );
};

export default Catalog;
