// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import Single from "../components/Single";
import Footer from "../components/Footer";

// Images
import AboutBackgroundImg from "../assets/about-hero.jpg";
import AboutImg from "../assets/about-img.svg";

const CONTENT = {
    heading:
        "AYAR DENETİM TEST ÖLÇÜM VE PERİYODİK KONTROL HİZMETLERİ LTD. ŞTİ.;",
    text: (
        <>
            - A Tipi bir Muayene Kuruluşu olarak tarafsız olmayı, tarafsızlığı
            tehlikeye atacak ticari, mali veya diğer baskılara izin vermemeyi,
            tarafsızlığa yönelik riskleri sürekli analiz ederek, bunun tüm
            personel tarafından anlaşılmasını sağlamayı, tarafsızlığa yönelik
            bir risk belirlendiğinde bu riski ortadan kaldırmak veya en aza
            indirmeyi,
            <br />
            <br />
            - Çıkar çatışmalarına mahal vermeden bağımsız bir şekilde
            davranmayı, ne olursa olsun bilimsel ve objektif olmayı,
            <br />
            <br />- Müşteri istek ve beklentilerini de göz önünde bulundurarak
            gizlilik, tarafsızlık, bağımsızlık, şeffaflık, yeterlilik,
            sorumluluk ve şikayetlerin çözümlenmesi ilkeleri çerçevesinde
            aksiyon almayı,
            <br />
            <br />- TS EN ISO/IEC 17020 Standardı’nın, kanun, yönetmelik ve
            tebliğler gibi ulusal ve uluslararası mevzuatın gereklerini yerine
            getirmeyi, güncelliğini takip etmeyi, sürekliliğini sağlamayı,
            sürekli iyileştirmeyi ve bu konuda tüm personelimizin görevleriyle
            ilgili tüm kalite gereklerini öğrenmelerini ve faaliyetlerinde
            uygulamalarını sağlayarak hizmet kalitemizi arttırmayı ve
            eğitimlerle bunun sürekliliğini sağlamayı , aynı zamanda
            müşterilerimizi de bilgilendirmeyi, bilinçlendirmeyi,
            farkındalıklarını arttırmayı,
            <br />
            <br />
            - Doğru, zamanında, güvenilir ve bilimsel hizmet üreterek periyodik
            kontrol alanında firmamızın sürekliliğini sağlarken, hizmet kalite
            standardımızı da sürekli iyileştirip geliştirerek sektörde katma
            değer yaratmayı,
            <br />
            <br />
            - İş sağlığı ve güvenliği prensiplerine göre faaliyet gösteren
            kuruluşlarda iş kazalarını, buna bağlı oluşacak başta insani kayıp
            ve zararlar olmak üzere, üretim kayıplarını ve cihaz hasarlarını
            engellemeye katkıda bulunmayı,
            <br />
            <br />- Dürüstlüğüyle, çalışkanlığıyla, yerinde ve zamanında
            müdahaleleriyle, mevzuattaki hükümlere uyumu, mevzuatın
            yorumlanmasında ve uygulanmasında yetkinliği ve katkılarıyla,
            sürekli iyileşmeyi temel alan vizyonuyla sektörde örnek olarak
            gösterilecek bir muayene kuruluşu olmayı hedeflemektedir.
            <br />
            <br />
            <b>Genel Müdür</b>
            <br />
            <b>08.06.2022</b>
        </>
    ),
};

const Quality = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={AboutBackgroundImg}
                img={AboutImg}
                text="KALİTE POLİTİKASI"
            />
            <Single CONTENT={CONTENT} />
            <Footer />
        </>
    );
};

export default Quality;
