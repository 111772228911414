import styled from "styled-components";

export const Container = styled.div`
    width: 85%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;

    @media only screen and (max-width: 1600px) {
        max-width: 1150px;
    }

    @media only screen and (max-width: 1300px) {
        max-width: 1100px;
    }
`;
