import { BrowserRouter, Routes, Route } from "react-router-dom";

// Styles
import { GlobalStyle } from "./sc/scGlobal";

// Components
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Documents from "./pages/Documents";
import About from "./pages/About";
import WhatWeDo from "./pages/WhatWeDo";
import Quality from "./pages/Quality";
import Text from "./pages/Text";
import Services from "./pages/Services";
import Service from "./pages/Service";
import Blog from "./pages/Blog";
import Catalog from "./pages/Catalog";
import ScrollToTop from "./components/ScrollToTop";

// Content
import { SERVICE_SHORT_CONTENT, SERVICE_LONG_CONTENT } from "./Content";

const App = () => {
    const renderService = (arr, type = true) => {
        return arr.map((content, i) => (
            <Route
                key={i}
                path={"hizmetler/" + content.name}
                element={
                    <Service content={content} type={type ? "short" : "long"} />
                }
            />
        ));
    };

    return (
        <>
            <GlobalStyle />
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        <Route index path="/" element={<Home />} />
                        <Route path="iletisim" element={<Contact />} />
                        <Route path="dokumanlar" element={<Documents />} />
                        <Route path="hakkimizda" element={<About />} />
                        <Route
                            path="hakkimizda/biz-kimiz"
                            element={<About />}
                        />
                        <Route
                            path="hakkimizda/ne-yapiyoruz"
                            element={<WhatWeDo />}
                        />
                        <Route
                            path="hakkimizda/kalite-politikasi"
                            element={<Quality />}
                        />
                        <Route
                            path="hakkimizda/tarafsizlik-bagimsizlik-gizlilik-taahhutnamesi"
                            element={<Text />}
                        />
                        <Route path="hizmetler" element={<Services />} />
                        <Route path="blog" element={<Blog />} />
                        <Route path="katalog" element={<Catalog />} />
                        {renderService(SERVICE_SHORT_CONTENT)}
                        {renderService(SERVICE_LONG_CONTENT, false)}
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </>
    );
};

export default App;
