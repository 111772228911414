// Styles
import { Container } from "../sc/scLayout";
import {
    AboutContainer,
    AboutMain,
    AboutHeading,
    AboutDivider,
    AboutButton,
    AboutText,
    AboutImgContainer,
    AboutImg,
    AboutReverseWrapper,
    AboutReverseImgContainer,
    AboutReverseImg,
    AboutReverseContentWrapper,
    AboutReverseContent,
} from "../sc/scAbout";

// Images
import Logo from "../assets/footer-logo.png";

const CONTENT = {
    heading: "BİZ KİMİZ?",
    text: (
        <>
            Ayar Denetim Test Ölçüm ve Periyodik Kontrol Hizmetleri olarak
            periyodik kontrol, ölçüm ve test alanında, mevzuata hakim, güncel
            yönetmelik ve standartları takip eden, sektör deneyimi olan,
            tecrübeli mühendislerle hizmet vermekteyiz.
            <br />
            <br />
            TS EN ISO/IEC 17020 standardı doğrultusunda akredite A Tipi Muayene
            Kuruluşu olarak ulusal ve uluslararası kontrol, standart ve test
            yöntemlerini uygulamakta, ilgili ekipman ve tesisatlarınızın
            kontrollerine yönelik doğru çözümler sunmaya ve böylece sektöre
            katkı sağlamaya çalışmaktayız.
        </>
    ),
};

const renderRegular = () => {
    return (
        <Container>
            <AboutContainer>
                <AboutMain>
                    <AboutHeading>{CONTENT.heading}</AboutHeading>
                    <AboutDivider />
                    <AboutText>{CONTENT.text}</AboutText>
                </AboutMain>
                <AboutImgContainer>
                    <AboutImg src={Logo} />
                </AboutImgContainer>
            </AboutContainer>
        </Container>
    );
};

const renderReverse = () => {
    return (
        <AboutReverseWrapper>
            <AboutReverseImgContainer>
                <AboutReverseImg src={Logo} alt="" />
            </AboutReverseImgContainer>
            <AboutReverseContentWrapper>
                <Container>
                    <AboutReverseContent>
                        <AboutHeading>{CONTENT.heading}</AboutHeading>
                        <AboutDivider />
                        <AboutText>{CONTENT.text}</AboutText>
                        <AboutButton to="/hakkimizda">Hakkımızda</AboutButton>
                    </AboutReverseContent>
                </Container>
            </AboutReverseContentWrapper>
        </AboutReverseWrapper>
    );
};

const AboutUs = ({ reverse }) => {
    return !reverse ? renderRegular() : renderReverse();
};

export default AboutUs;
