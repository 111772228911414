import { useLocation } from "react-router";

// Styles
import { Container } from "../sc/scLayout";
import {
    FooterWrapper,
    FooterTop,
    FooterBottom,
    FooterBrandingWrapper,
    FooterRightWrapper,
    FooterMenu,
    FooterHeading,
    FooterDivider,
    FooterLinks,
    FooterLink,
    FooterContact,
    FooterContactItem,
    FooterContactItemText,
    FooterLogoImg,
    FooterImg,
} from "../sc/scFooter";

// Images
import FooterLogo from "../assets/footer-logo.png";

// Contact
import { CONTACT } from "./ContactDetails";

// Constants
const MENU = [
    {
        link: "/",
        text: "Anasayfa",
    },
    {
        link: "/hakkimizda",
        text: "Hakkımızda",
    },
    {
        link: "/hizmetler",
        text: "Hizmetlerimiz",
    },
    {
        link: "/dokumanlar",
        text: "Dokümanlar",
    },
    {
        link: "/blog",
        text: "Blog",
    },
    {
        link: "/iletisim",
        text: "İletişim",
    },
];

const FooterBranding = () => {
    return (
        <FooterBrandingWrapper to="/">
            <FooterLogoImg src={FooterLogo} />
        </FooterBrandingWrapper>
    );
};

const FooterRight = () => {
    const location = useLocation();

    return (
        <FooterRightWrapper>
            <FooterMenu>
                <FooterHeading>Menü</FooterHeading>
                <FooterDivider />
                <FooterLinks>
                    {MENU.map((menuItem, i) => (
                        <FooterLink
                            key={i}
                            activeitem={
                                location.pathname === menuItem.link
                                    ? "true"
                                    : "false"
                            }
                            to={menuItem.link}
                        >
                            {menuItem.text}
                        </FooterLink>
                    ))}
                </FooterLinks>
            </FooterMenu>
            <FooterContact>
                <FooterHeading>İletişim</FooterHeading>
                <FooterDivider />
                {CONTACT.map((contactItem, i) => (
                    <FooterContactItem key={i}>
                        <FooterImg src={contactItem.img} />
                        <FooterContactItemText>
                            {contactItem.text}
                        </FooterContactItemText>
                    </FooterContactItem>
                ))}
            </FooterContact>
        </FooterRightWrapper>
    );
};

const Footer = () => {
    return (
        <FooterWrapper>
            <Container>
                <FooterTop>
                    <FooterBranding />
                    <FooterRight />
                </FooterTop>
            </Container>
            <FooterBottom>Copyright 2021 © Ayar Denetim</FooterBottom>
        </FooterWrapper>
    );
};

export default Footer;
