import styled from "styled-components";

export const DocumentsWrapper = styled.div`
    margin: 15rem 0 10rem;

    @media only screen and (max-width: 600px) {
        margin: 10rem 0 5rem;
    }
`;

export const DocumentsHeader = styled.div`
    display: flex;
    margin-bottom: 9rem;
`;

export const DocumentLink = styled.button`
    border: none;
    outline: none;
    background: transparent;
    font-family: "Montserrat", sans-serif;
    color: rgba(11, 11, 15, 0.6);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 1.3rem;
    transition: color 0.5s ease;
    cursor: pointer;
    position: relative;

    &:not(:last-child) {
        margin-right: 5rem;

        @media only screen and (max-width: 600px) {
            margin-right: 3rem;
        }
    }

    &:hover {
        color: rgb(11, 11, 15);
    }

    &:after {
        content: "";
        position: absolute;
        bottom: -2.25rem;
        left: 45%;
        transform: translateX(-50%);
        background-color: rgb(11, 11, 15);
        width: 7.5px;
        height: 7.5px;
        border-radius: 50%;
        transform: scale(0);
        transition: transform 0.5s ease;
    }

    ${(props) =>
        props.activeitem === "true" &&
        `
            color: rgb(11, 11, 15);

            &:after {
                transform: scale(1);
            }
    `};
`;

export const DocumentsMain = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const DocumentContainer = styled.div`
    width: 30%;
    margin-bottom: 10rem;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;

    &:not(:last-child) {
        margin-right: 3.3%;

        @media only screen and (max-width: 1024px) {
            margin-right: 5%;
        }

        @media only screen and (max-width: 600px) {
            margin-right: 0;
        }
    }

    @media only screen and (max-width: 1024px) {
        width: 45%;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 5rem;
        padding-bottom: 5rem;
        border-bottom: 0.5px solid rgba(11, 11, 15, 0.1);
    }
`;

export const DocumentMain = styled.a`
    margin-left: 3rem;
    text-decoration: none;

    &:hover {
        p {
            color: rgb(11, 11, 15);
        }
    }
`;

export const DocumentImg = styled.img``;

export const DocumentTag = styled.div`
    background-color: #e8d186;
    color: rgb(11, 11, 15);
    margin-bottom: 1.5rem;
    display: inline-block;
    padding: 6px 1.5rem;
    font-size: 1.2rem;
    border-radius: 3px;
`;

export const DocumentText = styled.p`
    white-space: pre-line;
    color: rgba(11, 11, 15, 0.6);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 1.3rem;
    line-height: 1.7;
    transition: color 0.5s ease;
`;
