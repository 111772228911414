// Components
import Header from "../components/Header";
import Hero from "../components/Hero";
import WhatWeDoText from "../components/WhatWeDoText";
import Footer from "../components/Footer";

// Images
import AboutBackgroundImg from "../assets/about-hero.jpg";
import AboutImg from "../assets/about-img.svg";

const WhatWeDo = () => {
    return (
        <>
            <Header />
            <Hero
                backgroundImg={AboutBackgroundImg}
                img={AboutImg}
                text="NE YAPIYORUZ"
            />
            <WhatWeDoText />
            <Footer />
        </>
    );
};

export default WhatWeDo;
